import React from "react";
import { useField } from "formik";
import "./Radio";
import classNames from "classnames";
import IconRadioChecked from "app/pages/.shared/static/icons/IconRadioChecked";
import IconRadioUnchecked from "app/pages/.shared/static/icons/IconRadioUnchecked";
import PropTypes from "prop-types";

const RadioInput = ({ children, showIcon = false, body, id, ...props }) => {
	const [field, meta] = useField({ ...props, type: "radio" });

	const radioClassName = classNames({
		radio: true,
		"radio--checked": field.checked,
		"radio--error": meta.touched && meta.error,
	});

	const iconNode = showIcon && (
		<div className="radio__icon">
			{field.checked ? (
				<IconRadioChecked width={15} height={15} />
			) : (
				<IconRadioUnchecked width={15} height={15} />
			)}
		</div>
	);

	const bodyNode = body ? <div className="radio__content">{body}</div> : false;

	return (
		<div className={radioClassName} data-cy={props["data-cy"]}>
			<input id={id} type="radio" className="radio__input" {...field} {...props} />
			<label className="radio__label" htmlFor={id}>
				<div className="radio__header">
					{iconNode}
					<div className="radio__text">{children}</div>
				</div>
				{bodyNode}
			</label>
		</div>
	);
};

RadioInput.propTypes = {
	disabled: PropTypes.bool,
	showIcon: PropTypes.bool,
	checked: PropTypes.bool,
	["data-cy"]: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.string.isRequired,
	toggle: PropTypes.func,
	body: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool]),
};

export default RadioInput;
